<template>
  <div>
    <h2 v-if="quickshopConfig.title" class="mb-4 py-3 subtitle-2">
      {{ $t[quickshopConfig.title] || quickshopConfig.title }}
    </h2>
    <section data-scroll-el="notificationSection">
      <vf-notification
        v-if="cart.notification.message"
        :type="cart.notification.type"
        class="mb-4 mt-3 <md:mb-2 md:mt-2"
        @close="cart.notification.message = ''"
      >
        {{ cart.notification.message }}
        <vf-link v-if="name === 'shopTheLook'" to="/cart" class="text-base">
          {{ $t.viewCart }}
        </vf-link>
      </vf-notification>
    </section>
    <div
      v-style="quickshopConfig.brandStyles.wrapper"
      :class="mode === 'inline'
        ? `lg:absolute lg:-top-8 lg:-inset-x-2 lg:z-3 lg:bg-white lg:pt-8 lg:pb-4 lg:px-2 lg:shadow-md `
        : quickshopConfig.brandClasses.wrapper"
      @keydown.esc="emit('resolve')"
    >
      <define-badge-template>
        <product-badge v-if="hasBadge && mode === 'modal'" v-bind="details?.badge" class="<md:my-1" />
      </define-badge-template>
      <define-title-template>
        <p
          class="line-clamp-3 "
          :class="[classProductName, { 'lg:text-sm ': mode === 'inline' }]"
        >
          {{ details?.name }}
        </p>
      </define-title-template>
      <define-eyebrow-template>
        <template v-if="details?.eyebrow">
          <div v-if="$feature.showSkeletonsOnQuickshop && pending" class="mb-3 h-2 w-18 skeleton" />
          <p v-else-if="details.eyebrow.label" class="mb-1 mt-2 truncate eyebrow">
            {{ details.eyebrow.label }}
          </p>
        </template>
      </define-eyebrow-template>

      <define-product-heading>
        <!-- Product Detail -->
        <div class="b-grey-70 lg:b-b lg:pb-4" :class="mode === 'modal' ? 'lg:mb-4' : 'md:b-b md:mb-2 md:pb-4'">
          <div :class="{ 'mt-6 <lg:mb-6': mode === 'modal' && $slots.default }">
            <badge-template v-if="$feature.showBadgeAboveTitle" />
            <eyebrow-template v-else />
            <div :class="quickshopConfig.brandClasses.namePriceWrapper">
              <title-template />
              <eyebrow-template v-if="$feature.showBadgeAboveTitle" />
              <badge-template v-else />
              <div
                v-if="$feature.showSkeletonsOnQuickshop && pending && details?.price"
                class="mb-2 mt-3 h-2 w-18 skeleton"
              />
              <product-pricing
                v-else-if="!pending && !isDigitalProduct"
                :price="productPrice"
                :currency="(details?.currency || product?.currency)!"
                :show-discount-percentage="$feature.showDiscountPercentageOnCatalog"
                class="mt-2 text-sm"
                :class="[quickshopConfig.brandClasses.price, { 'mt-4': mode === 'modal' }]"
                show-original-price-for-omnibus
              />
            </div>
          </div>
          <slot name="belowPrice" :product="details" :product-price="productPrice" />
        </div>
      </define-product-heading>

      <base-button
        v-if="mode === 'inline'"
        :aria-label="$t.close"
        class="absolute right-0 top-0 mr-2 mt-1 <lg:hidden"
        @click="emit('resolve')"
      >
        <vf-icon name="close" size="md" />
      </base-button>

      <div v-if="$slots.default">
        <slot />
        <product-heading />
      </div>

      <div class="relative row-span-3 row-start-1 mb-2 w-full ">
        <slot name="gallery">
          <!-- Image Carousel -->
          <vf-carousel
            v-if="details?.gallery?.length"
            :key="details.gallery.length"
            :class="quickshopConfig.carousel?.brandClasses.wrapper"
            :class-container="quickshopConfig.carousel?.brandClasses.container"
            :loop="details.gallery.length > 1"
            @paginate="handleChange"
            @scroll-end="handleChange"
            @to-next="handleChange"
            @to-prev="handleChange"
          >
            <div
              v-for="(item, i) in details.gallery"
              :key="i"
              class="relative w-full"
              :class="quickshopConfig.carousel?.brandClasses?.item"
              :style="{ 'aspect-ratio': galleryAspectRatio }"
            >
              <base-picture
                v-if="item.type === 'image'"
                tabindex="0"
                :src="getImageTransformations(item.src, gallery.presets.src)"
                :width="item.width || gallery.presets.src.width"
                :height="item.height || gallery.presets.src.height"
                :alt="item.alt"
                class="absolute-0 full"
              />
              <base-video
                v-else-if="item.type === 'video'"
                ref="videos"
                :src="item.src"
                :meta="item.meta"
                :poster="item.meta?.poster"
                class="absolute-0 full"
                controls
                @play="handlePlay(i)"
              />
            </div>
            <template v-if="quickshopConfig.carousel?.pagination" #pagination="{ activeItem, pages }">
              <div
                class="max-w-full flex justify-center wrap space-x-2"
                :class="[
                  mode === 'modal' ? 'mt-4' : 'mt-2',
                  { 'md:pb-5': mode === 'modal' && $slots.default },
                ]"
              >
                <div
                  v-for="(_, i) in pages"
                  :key="i"
                  class="h-2 w-2 b b-grey-40 rounded-full"
                  :class="{ 'bg-grey-10 b-grey-10 ': i === activeItem }"
                  :aria-current="(i === activeItem) || undefined"
                />
              </div>
            </template>
          </vf-carousel>
          <div
            v-else-if="$feature.showSkeletonsOnQuickshop"
            class="mb-4 skeleton"
            :style="{ 'aspect-ratio': galleryAspectRatio }"
          />
        </slot>

        <slot name="badge">
          <product-badge
            v-if="hasBadge && mode === 'inline'"
            v-bind="details?.badge"
            class="absolute right-0 mr-2 bg-white px-2 py-1 "
            :class="badgePosition === 'bottom'
              ? quickshopConfig.carousel?.pagination ? 'bottom-0 mb-6' : 'bottom-0 mb-2'
              : 'top-0 mt-2'"
          />
        </slot>

        <slot name="belowGallery" />
      </div>

      <div class="col-start-2">
        <product-heading v-if="!$slots.default" />

        <template v-if="pending && $feature.showSkeletonsOnQuickshop">
          <!-- Color Selection Skeleton -->
          <template v-for="({ label, options }, j) in colorAttributes" :key="j">
            <p class="mt-4 text-sm" :class="quickshopConfig.brandClasses.label">
              {{ label }}:
            </p>
            <div class="mb-4 mt-3 flex wrap">
              <div v-for="i in options.length" :key="i" class="h-10 w-10 p-2">
                <div class="full skeleton rounded-full" :class="{ '<lg:w-8 <lg:h-8 <lg:mx-4': mode === 'modal' }" />
              </div>
            </div>
          </template>

          <!-- Size Selection Skeleton -->
          <div class="mb-6">
            <p class="mt-4 text-sm">
              <span :class="quickshopConfig.brandClasses.label">Size:</span>
            </p>
            <div class="mt-2 flex gap-2 wrap">
              <div v-for="_size in 12" :key="_size" class="h-10 w-10 skeleton" />
            </div>
            <div class="mb-3 mt-5 h-3 w-11 skeleton" />
            <div class="h-10 max-w-full w-82 skeleton" />
          </div>
        </template>
        <div v-else-if="details && isDigitalProduct" ref="sizePickersEl" class="mb-6">
          <form-gift-card
            ref="formRef"
            v-model:attribute-selection="attributeSelection"
            v-model:form="giftCardFormFields"
            v-bind="{ product: details, pending: detailsPending, error }"
            v-on="{ selectCardStyle: (id, _, label) => selectColor(id, label), selectCardAmount: selectSize }"
          >
            <template #formCta="{ form }">
              <vf-form-field
                v-if="!context"
                v-slot="{ attrs }"
                name="policy"
                :rule="validateRequired('', $t.validators.requiredCheckbox)"
              >
                <vf-checkbox v-model="form.policy" v-bind="attrs" class="mb-2 mt-6">
                  <form-legal-disclaimer :content="$t.brandAgreement.termsAndConditions" />
                </vf-checkbox>
              </vf-form-field>
            </template>
          </form-gift-card>
        </div>
        <template v-else>
          <!-- Color Selection -->
          <div
            class="b-grey-70"
            :class="mode === 'modal' && $slots.default ? '<lg:b-t <lg:pt-4' : '<lg:mt-4 <lg:b-t <lg:pt-4'"
          >
            <template v-for="({ label, options, type }, j) in colorAttributes" :key="j">
              <p class="text-sm " :class="{ 'mt-2': mode === 'inline' }">
                <span :class="quickshopConfig.brandClasses.label">{{ label }}:</span>
                {{ attributeSelectionLabels[type] }}
              </p>
              <div
                class="flex pt-2 wrap "
                :class="{ 'gap-1': shape === 'rectangle', 'pt-3': mode === 'inline' }"
              >
                <vf-color-picker
                  v-for="{ available, id: optionId, label: optionLabel, swatch, value } in options"
                  :key="value"
                  :color="swatch?.color"
                  :thumbnail="swatch?.image"
                  :variant="shape"
                  :unavailable="!available"
                  :name="optionLabel"
                  :active="!pending && getIsActive(value, optionId)"
                  @click="selectColor(optionId, value)"
                />
              </div>
            </template>
          </div>

          <div v-if="product && isComingSoon" class="mb-6 mt-4 space-y-4">
            <p class="title-4 c-grey-20">
              {{ comingSoonText }}
            </p>
            <p v-if="selectedVariant?.notifyMe">
              {{ replaceAll($t.comingSoonDescription, { productName: product.name }) }}
            </p>
          </div>

          <!-- Size Selection -->
          <div
            v-else
            ref="sizePickersEl"
            class="mt-4 f-col gap-y-4"
            :class="mode === 'modal' && $slots.default ? 'mb-4' : 'mb-6'"
          >
            <base-form
              ref="formAttributesRef"
              :form="attributeSelection"
              :scroll-to-error="false"
              class="space-y-4 "
            >
              <div v-for="({ label, options, type }, i) of sizeAttributes" :key="i">
                <base-form-field v-slot="{ attrs, invalid }" :name="type" :rule="validateRequired()">
                  <fieldset>
                    <legend class="pt-2 text-sm">
                      <span :class="quickshopConfig.brandClasses.label">{{ label }}:</span>
                      {{ attributeSelectionLabels[type] }}
                    </legend>
                    <vf-form-error v-if="error && invalid" class="mt-1">
                      {{ $t.selectSizeError }}
                    </vf-form-error>
                    <div
                      class="mt-2 flex gap-2 wrap "
                      :class="{ 'b-b b-grey-70 pb-6': mode === 'modal' && $slots.default }"
                    >
                      <vf-size-picker
                        v-for="({ available, label: optionLabel, value }, j) in options"
                        :key="optionLabel"
                        :type
                        :model-value="attributeSelection[type]"
                        :value
                        :name="`${i}-${details?.id || product?.id}-quickshop`"
                        :required="j === 0"
                        :unavailable="!available || !getAttrOptionAvailable(type, value)"
                        v-bind="attrs"
                        @input="selectSize(type, value, optionLabel)"
                      >
                        {{ optionLabel }}
                      </vf-size-picker>
                    </div>
                  </fieldset>
                </base-form-field>
              </div>
            </base-form>
          </div>
          <vf-notification
            v-if="mode === 'inline' && cart.notification.message"
            :type="cart.notification.type"
            class="mb-6"
          >
            {{ cart.notification.message }}
          </vf-notification>
        </template>
        <!-- Quickshop Actions -->
        <base-sticky v-slot="{ isStuck }" position="bottom" name="footerQuickshop">
          <div
            class="bg-white"
            :class="[
              quickshopConfig.brandClasses.stickyFooter,
              {
                '<lg:sticky <lg:bottom-0 <lg:py-4': !$slots.default,
                'bottom-0': mode === 'modal' && !$slots.default,
              },
            ]"
          >
            <span
              v-if="isStuck"
              class="pointer-events-none absolute-0 shadow-top -mx-4 md:-mx-10"
              :class="{ 'lg:hidden': !quickshopConfig.stickyFooter }"
            />
            <vf-button
              v-if="showNotifyCta"
              class="w-full "
              :size="quickshopConfig.ctaSize"
              @click="viewDetails"
            >
              {{ $t.notifyCta }}
            </vf-button>
            <vf-button
              v-else-if="isComingSoon && !pending"
              class="w-full "
              disabled
              :size="quickshopConfig.ctaSize"
            >
              {{ comingSoonLabel }}
            </vf-button>
            <vf-button
              v-else-if="(isSelectedVariantOutOfStock || allVariantsOutOfStock) && !pending"
              class="w-full "
              disabled
              :size="quickshopConfig.ctaSize"
            >
              {{ $t.outOfStock }}
            </vf-button>
            <vf-button
              v-else-if="details?.presale && !auth.loggedIn"
              :size="quickshopConfig.ctaSize"
              class="w-full "
              @click="signInToBuy"
            >
              {{ $t.signInToBuy }}
            </vf-button>
            <div v-else-if="context" class="min-w-1/2 w-full grow">
              <vf-button
                :size="quickshopConfig.ctaSize"
                :loading="loading"
                class="w-full "
                @click="updateInCart"
              >
                {{ $t.updateInCart }}
              </vf-button>
            </div>
            <div v-else class="grow">
              <vf-button
                :size="quickshopConfig.ctaSize"
                :loading="loading || pending"
                class="w-full "
                @click="addToCart"
              >
                {{ $t.addToCart }}
              </vf-button>
            </div>
            <slot name="viewDetailsCta" :product="details" :attribute-selection="attributeSelection">
              <vf-button
                :variant="quickshopConfig.viewDetailsVariant"
                :size="quickshopConfig.ctaSize"
                class="w-full "
                @click="viewDetails"
              >
                {{ $t.viewDetails }}
              </vf-button>
            </slot>
          </div>
        </base-sticky>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatDate } from '@vueuse/core'
import type { LocationQueryRaw } from 'vue-router'
import { ApiErrorCode } from '#root/enums/api'
import type { Responsive } from '#types/common'
import type { TextSizes } from '#types/sizes'
import type { GiftOption } from '#types/cart'
import type { GiftCardFormFields, ProductAttributeType } from '#types/product'
import type { Inventory, Product, ProductPrice, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { BaseForm as BaseFormType, BaseVideo as BaseVideoType, FormGiftCard as FormGiftCardType } from '#components'

export type Props = {
  productId?: string
  product?: Product
  // flag to determine whether to show Add to Cart or Update in Cart button
  context?: 'update-cart-item' | 'update-saved-item'
  mode?: 'modal' | 'inline'
  giftOption?: GiftOption
  productNameSize?: TextSizes | Responsive<TextSizes>
  initialVariants?: ProductVariants[number]['attributes']
  updateProductId?: (id: string) => void
}
export type Emits = {
  'resolve': []
  'update:productId': [id: string]
}

const { context, giftOption, initialVariants, mode, product, productNameSize } = withDefaults(
  defineProps<Props>(),
  {
    mode: () => 'modal',
    productNameSize: () => ({ sm: 'sm', md: 'md' })
  }
)

const emit = defineEmits<Emits>()

const productId = defineModel<string>('productId')

const currentDialog = useCurrentDialog<Props, Emits>()
const { ModalSignIn } = useDialogsStore()
const [DefineProductHeading, ProductHeading] = createReusableTemplate()
const [DefineBadgeTemplate, BadgeTemplate] = createReusableTemplate()
const [DefineTitleTemplate, TitleTemplate] = createReusableTemplate()
const [DefineEyebrowTemplate, EyebrowTemplate] = createReusableTemplate()

// Global
const { products } = useApi()
const { quickshop: quickshopConfig } = useAppConfig().components.dialog
const {
  card: { badgePosition, maxCarouselItems },
  swatches: { shape }
} = useAppConfig().components.product
const { enableColorAsAttribute, gallery } = useAppConfig().pages.pdp
const { name } = useAttrs()
const auth = useAuthStore()
const cart = useCartStore()
const { validateRequired } = useLocalizedValidators()
const { $feature, $gtm, $t, $viewport } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const toast = useToaster()

// Local
const galleryAspectRatio = gallery.presets.src.width / gallery.presets.src.height
const attributeSelection = reactive(
  initialVariants || Object.assign({}, product?.variants || {})
) as unknown as ProductVariants[number]['attributes']
const activeProduct = ref(productId.value || product?.id || '')
const error = ref(false)
const filtersQuery = JSON.parse(route.query.filters as string || '{}')
const loading = ref(false)
const sizePickersEl = ref<HTMLElement>()
const videos = ref<InstanceType<typeof BaseVideoType>[]>([])
const formRef = ref<InstanceType<typeof FormGiftCardType>>()
const formAttributesRef = ref<InstanceType<typeof BaseFormType> | null>(null)
const isComingSoon = computed(() => $feature.showComingSoonOnPDP && (product?.badge?.id || product?.eyebrow?.id)?.toUpperCase() === 'COMING SOON')
const comingSoonLabel = computed(() => isComingSoon.value ? (product?.badge || product?.eyebrow)?.label : undefined)

const giftCardFormFields = ref<GiftCardFormFields>({
  email: giftOption?.email || '',
  fullName: giftOption?.to || '',
  message: giftOption?.message || '',
  amount: (giftOption && initialVariants?.size === 'X' && product?.price.lowCurrent.toString()) || '',
  size: attributeSelection?.size || ''
})

// Fetch
const { productPending: detailsPending, product: details } = await useProduct(activeProduct, {
  server: false,
  // @ts-expect-error incorrect Nuxt types
  default: () => (product),
  // Temporary fix for missing data from API
  transform: (input) => ({
    ...input,
    eyebrow: input.eyebrow || product?.eyebrow,
    badge: input.badge || product?.badge,
    ...(maxCarouselItems && { gallery: input.gallery?.slice(0, maxCarouselItems) || [] })
  }),
  onResponse({ response: { _data: { id } } }) {
    productId.value = id
  }
})

const hasBadge = computed(() => details.value?.badge?.label || details.value?.badge?.icon)
const isDigitalProduct = computed(() => details.value?.productType?.toLowerCase() === 'digital')

let inventory = ref<Inventory | null>(null)
let inventoryPending = ref(false)

// We want to skip the inventory check for digital products, but we only know in advance that
// the product is digital on update cart/saved item - on PLP/SRP we can only tell after we've
// fetched the product details, which would mean having to wait before calling the inventory.
// Doing an extra request is a better proposition than having synchronous requests,
// so we'll also call the inventory service for digital products, and just won't use the response.
if (!giftOption) {
  ({ pending: inventoryPending, data: inventory } = products.inventory(activeProduct, {}, {
    key: `productInventory-${activeProduct.value}`
  }))
}

const classProductName = getResponsiveClasses(productNameSize, 'text')

// Computed

const pending = computed(() => detailsPending?.value || inventoryPending?.value)

const variants = computed(() => Array.isArray(details.value?.variants)
  ? details.value.variants.map((variant) => {
    const { quantity = 0 } = inventory.value?.variants[variant.id] || {}
    return {
      ...variant,
      productInventoryState: isDigitalProduct.value || quantity > 0 ? 'InStock' : 'OutOfStock'
    }
  })
  : [])

// Attribute selection from filtersQuery
const initialFilterSelection = computed(() => {
  const initialSelection = {}
  Object.keys(filtersQuery).forEach((label) => {
    // Find attribute by label, URL filters use label instead of type
    const attribute = details.value?.attributes.find((attribute) => {
      const attributeLabel = attribute.label?.toLowerCase()
      const filterLabel = label?.toLowerCase()

      return filterLabel?.includes(attributeLabel) || attributeLabel?.includes(filterLabel!)
    })

    if (attribute) {
      const option = attribute?.options.find((option) => {
        // Select if there is only one filter option
        const [filterOption] = filtersQuery?.[label]
        return option.label?.toLowerCase() === filterOption?.toLowerCase() && filtersQuery[label].length === 1
      })
      // Set initial selection for an attribute type
      if (option)
        initialSelection[attribute.type] = option.value
    }
  })
  return initialSelection
})

const attributes = computed(() => details.value?.attributes.map((attribute) => ({
  ...attribute,
  options: attribute.options.map((option) => {
    // VariantIds are a list of all variant IDs
    const variantIds = variants.value
      .filter((variant) => variant.attributes[attribute.type] === option.value)
      .map((variant) => variant.id)
    return attribute.type === 'color'
      ? {
          ...option,
          available: (option.id === activeProduct.value)
            ? variants.value.some(({ productInventoryState }) => productInventoryState === 'InStock')
            : option.available
        }
      : {
          ...option,
          available: variants.value.filter(({ id }) => variantIds?.includes(id))
            .some(({ productInventoryState }) => productInventoryState === 'InStock')
        }
  })
})) || [])

const attributeSelectionLabels = computed(() =>
  attributes.value?.reduce((attributes, { type, options }) => {
    attributes[type] = options?.find((option) => option.value === attributeSelection[type])?.label
    return attributes
  }, {})
)

const colorAttributes = computed(() => attributes.value.filter(({ type }) => type === 'color'))
const sizeAttributes = computed(() => attributes.value.filter(({ type }) => type !== 'color'))
const isVariantPicked = computed(() => attributes.value.every(({ type }) =>
  attributeSelection[type]
  || type === 'color'
  || (type === 'size' && giftCardFormFields.value.amount))
)
const selectedVariant = computed(() => {
  const variant = variants.value.find((variant) =>
    Object.entries(variant.attributes)?.every(
      ([key, val]) =>
        attributeSelection[key] === val
        || (key === 'size' && val === 'X' && giftCardFormFields.value.amount)
    )
  )
  return variant && isVariantPicked.value ? variant : null
})
const isSelectedVariantOutOfStock = computed(() => isVariantPicked.value && selectedVariant.value?.productInventoryState !== 'InStock')
const allVariantsOutOfStock = computed(() => variants.value.every((variant) => variant.productInventoryState === 'OutOfStock'))

const showNotifyCta = computed(() =>
  (($feature.showNotifyMeOnQuickshop && selectedVariant.value?.notifyMe && isSelectedVariantOutOfStock.value)
  || (selectedVariant.value?.notifyMe && isComingSoon.value))
  && !pending.value)

const productPrice = computed<ProductPrice>(() => {
  if (selectedVariant.value?.price) {
    return {
      highCurrent: selectedVariant.value.price.current ?? selectedVariant.value.price.original,
      lowCurrent: selectedVariant.value.price.current ?? selectedVariant.value.price.original,
      highOriginal: selectedVariant.value.price.original,
      lowOriginal: selectedVariant.value.price.original,
      historicBestPrice: selectedVariant.value.price.historicBestPrice,
      percentagePriceDiscount: selectedVariant.value.price.percentagePriceDiscount
    }
  }
  return details.value?.price || product?.price || { highCurrent: 0, lowCurrent: 0, highOriginal: 0, lowOriginal: 0 }
})

const itemGiftOption = computed(() => isDigitalProduct.value
  ? {
      to: giftCardFormFields.value.fullName,
      email: giftCardFormFields.value.email,
      message: giftCardFormFields.value.message
    }
  : undefined
)
const itemCustomAmount = computed(() => giftCardFormFields.value.amount
  ? {
      amount: +giftCardFormFields.value.amount
    }
  : undefined
)

const comingSoonText = computed(() => {
  const targetPublishDate = product?.targetPublishDate ?? details.value?.targetPublishDate

  return targetPublishDate
    ? `${$t.comingSoon} - ${formatDate(new Date(targetPublishDate.replace(/-/g, '\/')), $feature.configProductComingSoonDateFormat)}`
    : $t.comingSoon
})

// Methods
async function addToCart() {
  error.value = (formRef.value && !formRef.value?.validate(true)) || !isVariantPicked.value

  if (error.value || !selectedVariant.value) {
    sizePickersEl.value?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    formAttributesRef.value?.validate(true)
    return
  }

  loading.value = true

  if (selectedVariant.value.id) setGtmCartProductsMap(selectedVariant.value.id, details.value)

  try {
    await withRetryAfterTime(() => cart.add({
      product: selectedVariant.value!.id,
      upc: selectedVariant.value!.upc,
      suppressMiniCart: name === 'shopTheLook',
      giftOption: itemGiftOption.value,
      customAmount: itemCustomAmount.value,
      maxQty: inventory.value?.variants[selectedVariant.value!.id]?.quantity
    }), 2000, [ApiErrorCode.GWP_PRODUCT])
  }
  catch (err) {
    log.error(err)
  }

  loading.value = false

  if (cart.error) {
    cart.setNotification('error', cart.errorMessage)
    if (selectedVariant.value.id && details.value) removeGtmCartProductsMap(selectedVariant.value.id, details.value.id)
  }
  else {
    formRef.value?.reset()
  }

  if (!$feature.showMiniCartOnAddProduct)
    emit('resolve')
}

const getAttrOptionAvailable = (type: ProductAttributeType, value: string) => {
  // Attribute types except the current type and 'color'
  const otherAttributeTypes = attributes.value.filter((attribute) => attribute.type !== type && attribute.type !== 'color')
    .map((attribute) => attribute.type)
  if (otherAttributeTypes?.every((attributeType) => attributeSelection[attributeType])) {
    // If all other attribute types are selected, find the current option's variant
    const currentVariant = variants.value.find((variant) =>
      otherAttributeTypes.every((attributeType) =>
        variant.attributes[attributeType] === attributeSelection[attributeType])
        && variant.attributes[type] === value)

    return currentVariant?.productInventoryState === 'InStock'
  }

  // When the attribute option has other unselected attribute types, it's available
  return true
}

const handleChange = () => videos.value.forEach((video) => video.$el?.pause())

const handlePlay = (i: number) => {
  videos.value.forEach(({ $el }) => $el.pause())
  videos.value[i].$el.play()
}

const preselectAttributes = () => {
  attributes.value.forEach(({ type, options }) => {
    const availableOptions = options.filter(({ available }) => available)
    if (type === 'color') {
      const colorSelection = colorAttributes.value[0].options.find(({ id }) => id === activeProduct.value)?.value
      const defaultColor = (options?.find(({ defaultColor }) => defaultColor) || options[0])?.value

      attributeSelection.color = colorSelection || defaultColor
    }
    else if (options.length === 1) {
      attributeSelection[type] = options[0].value
    }
    else if (availableOptions.length === 1) {
      attributeSelection[type] = availableOptions[0].value
    }
    else {
      attributeSelection[type] = initialVariants?.[type] || initialFilterSelection.value[type] || attributeSelection[type] || ''
    }

    if (!attributeSelection[type] && $feature.enablePreselectFirstWidth && type === 'width')
      attributeSelection[type] = availableOptions[0]?.value || options[0].value
  })
  formAttributesRef.value?.validate()
}

const getIsActive = (value: string, id: string) =>
  enableColorAsAttribute ? attributeSelection.color === value : activeProduct.value === id

const selectColor = (id: string, label: string) => {
  attributeSelection.color = label
  activeProduct.value = id
  $gtm.push('plpPage.onSelectColor', label)
}

const selectSize = (type: string, value: string, label: string) => {
  attributeSelection[type] = value
  giftCardFormFields[type] = value
  const gtmEvent = name === 'shopTheLook' ? 'shopTheLook.onSelectSize' : 'plpPage.onSelectSize'
  $gtm.push(gtmEvent, `${activeProduct.value} - ${label}`)
}

const updateInCart = async () => {
  loading.value = true
  error.value = (formRef.value && !formRef.value?.validate(true)) || !isVariantPicked.value
  try {
    // check if all attributes are selected
    if (!error.value && selectedVariant.value && product?.itemId) {
      if (context === 'update-cart-item') {
        await cart.updateItemInCart(
          product.itemId,
          product.id,
          activeProduct.value,
          selectedVariant.value.id,
          selectedVariant.value.upc,
          itemGiftOption.value,
          itemCustomAmount.value
        )
      }

      if (context === 'update-saved-item')
        await cart.updateSavedItemInCart(product.itemId, selectedVariant.value.id)

      toast.add({
        props: {
          title: $t.success,
          message: replaceAll($t.cartItemEdited, { name: details.value?.name }),
          type: 'success',
        }
      })

      emit('resolve')
    }
    else {
      sizePickersEl.value?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      formAttributesRef.value?.validate(true)
    }
  }
  catch (err) {
    assertApiError(err)
    toast.add({
      props: {
        type: 'error',
        title: err.errorId === ApiErrorCode.ADDED_MAX_QUANTITY ? '' : $t.somethingWentWrong,
        message: err.errorId === ApiErrorCode.ADDED_MAX_QUANTITY
          ? cart.getMaxAllowedQtyMessage(err)
          : $t.unableToUpdateCartItem
      }
    })
    emit('resolve')
  }
  finally {
    loading.value = false
  }
}

const signInToBuy = async () => {
  if (await ModalSignIn.keep($viewport.lg && mode === 'inline').open()) {
    if (mode !== 'inline' && context !== 'update-cart-item') return currentDialog.reopen({ initialVariants: attributeSelection })
    await until(() => cart.pending).toBe(false)
    context === 'update-cart-item' ? updateInCart() : addToCart()
  }
}

const viewDetails = async () => {
  $gtm.push('product.onProductClick', (details?.value || product)!, {
    action: 'Click View Details',
    breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
    category: history.state.category,
    searchTerm: route.query.q?.toString()
  })

  emit('resolve')

  const selection = Object.entries(attributeSelection).filter(([key, value]) =>
    enableColorAsAttribute ? value : key !== 'color' && value
  )

  await router.push({
    path: details.value?.url || product?.url,
    state: {
      breadcrumbs: history.state.breadcrumbs,
      category: history.state.category,
      searchTerm: route.query.q?.toString()
    },
    query: {
      ...(selection.length && {
        ...Object.fromEntries(selection)
      })
    } as unknown as LocationQueryRaw
  })
}

onMounted(async () => {
  if (currentDialog.reopened) {
    await until(sizePickersEl).toBeTruthy()
    addToCart()
  }
})

onUnmounted(() => {
  cart.clearNotification()
})

watch(details, (value) => {
  $gtm.push('product.onProductDetailView', value, {
    viewType: 'Quick Shop',
    breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
    category: history.state.category,
    searchTerm: route.query.q?.toString()
  })
})

watch(cart.notification, () => {
  if (cart.notification.message)
    scrollToElement('notificationSection')
})

whenever(() => !pending.value, () => {
  preselectAttributes()
})
</script>
