<template>
  <article class="relative h-full f-col space-y-2">
    <base-link class="grow" :to="url">
      <base-picture
        :src="getResponsiveImageTransformations(image.src, size)"
        :alt="image.alt"
        v-bind="size"
        class="w-full"
      />
    </base-link>
    <div v-if="rating?.showScore" class="flex items-center gap-1">
      <vf-rating :value="rating?.score" size="xs" />
    </div>
    <base-link class="grow" :to="url">
      <p class="line-clamp-2 text-sm fw-medium ">
        {{ title }}
      </p>
    </base-link>
    <product-pricing :price :currency class="grow text-sm" />
    <vf-button
      variant="primary"
      :size="addButtonSize"
      class="relative mt-4"
      :class="brandClasses.addButton"
      data-test-id="product-card-add"
      @click="ModalOrPanelQuickshop.open({ product })"
    >
      <vf-icon name="plus" class="mr-1" />
      {{ $t.add }}
    </vf-button>
    <modal-or-panel-quickshop :size="$viewport.lg ? 'lg' : undefined" position="bottom" class-content="<lg:pb-0" />
  </article>
</template>

<script lang="ts" setup>
import type { ProductVariants } from '#root/api/clients/product/data-contracts'
import type { ImageItem } from '#types/media'
import type { MonetateRating } from '#types/monetate'
import type { PriceFull } from '#types/product'

const { title, url, image, rating, id, currency, price } = defineProps<{
  /**
   * Product name or title
   */
  title: string
  /**
   * Path to product detail page
   */
  url: string
  /**
   * Default product image
   */
  image: ImageItem
  /**
   * Product rating data
   */
  rating?: MonetateRating
  /**
   * Product id
   */
  id: string
  /**
   * Product price currency
   */
  currency: string
  /**
   * Product price data
   */
  price: PriceFull
}>()

const { addButtonSize, brandClasses, presets: { size } = {} } = useAppConfig().components.cms.productRecommendations
const { $viewport } = useNuxtApp()

const product = computed(() => ({
  id,
  name: title,
  url,
  price,
  currency,
  attributes: [],
  variants: {} as ProductVariants
}))

const ModalOrPanelQuickshop = createDialog('quickshop', {
  wrapper: () => $viewport.lg ? 'modal' : 'panel',
})
</script>
