<template>
  <div class="relative f-col " data-test-id="product-card">
    <section :class="brandClasses.sectionGallery">
      <product-card-gallery
        :items="data.gallery"
        :lazy
        :name="data.name"
        :show-secondary-media="showSecondaryMedia"
        :fetchpriority
        @click-item="navigateToPDP()"
      />
      <product-badge
        v-if="data.badge?.label"
        v-bind="data.badge"
        class="pointer-events-none absolute right-0 mr-2 bg-white px-2 py-1 "
        :class="badgePosition === 'bottom' ? 'bottom-0 mb-2' : 'top-0 mt-2'"
        data-test-id="product-card-badge"
      />
    </section>
    <!-- color swatches -->
    <template v-if="swatches?.length">
      <product-swatches
        v-if="showSwatches"
        v-model="activeProduct"
        :swatches
        :lazy
        class="z-1"
        @more="navigateToPDP()"
      />
      <!--   show more performant text version when swatches aren't enabled   -->
      <p
        v-else-if="!hideAttributes && swatches.length"
        class="text-sm underlined"
        :class="brandClasses.swatchCount"
        data-test-id="product-card-colors"
      >
        {{ showSwatchCount ? `${swatches.length} ${$pluralize($t.colors, swatches.length)}` : $t.moreColors }}
      </p>
    </template>
    <!--   eyebrow   -->
    <p
      v-if="data.eyebrow?.label"
      class="truncate eyebrow"
      :class="brandClasses.eyebrow"
      data-test-id="product-card-eyebrow"
    >
      {{ data.eyebrow.label }}
    </p>
    <!--   title   -->
    <base-link
      :to="data.url"
      :class="[classTitle, brandClasses.title]"
      data-test-id="product-card-title"
      @click.capture="navigateToPDP($event)"
    >
      <template v-if="!$feature.enableProductCardGalleryCarousel">
        <span class="absolute-0 mb-8" />
        <span
          class="absolute inset-x-0 top-0"
          style="padding-top: 100%"
          @mouseenter="showSecondaryMedia = $viewport.lg"
          @mouseleave="showSecondaryMedia = false"
        />
      </template>
      {{ data.name }}
    </base-link>
    <!--   rating   -->
    <div v-if="!hideRating && data.rating?.reviewsCount" class="mt-1 flex items-center gap-1">
      <vf-rating :value="data.rating.score" :size="ratingSize" data-test-id="product-card-rating" />
      <span class="text-xs c-grey-20">
        ({{ data.rating.reviewsCount }})
      </span>
    </div>
    <!--   price   -->
    <product-pricing
      :price="data.price"
      :currency="data.currency"
      :show-discount-percentage="showDiscountPercentage"
      class="mt-2 grow text-sm"
      data-test-id="product-card-pricing"
    />

    <!-- Show Quickshop -->
    <template v-if="quickshopMode !== 'off'">
      <vf-button
        variant="tertiary"
        :size="addButtonSize"
        class="relative mt-4"
        :class="brandClasses.addButton"
        data-test-id="product-card-add"
        @click="openQuickshop"
      >
        <vf-icon name="plus" class="mr-1" />
        {{ $t.add }}
      </vf-button>

      <modal-or-panel-quickshop
        v-slot="props"
        :size="$viewport.lg ? 'lg' : undefined"
        position="bottom"
        class-content="<lg:pb-0"
      >
        <!--
          Temporary hack to make inline quickshop work with "nested" transition
          because currently "createTemplatePromise" doesn't support dynamic transition names
         -->
        <div :class="{ 'z-2': mode === 'inline' }">
          <dialog-quickshop
            v-bind="props"
            ref="quickshopRef"
            v-model:product-id="activeProduct"
            :class="{ 'transition [.nested-enter-from_&,.nested-leave-to_&]:op-0': mode === 'inline' }"
            :mode="mode"
          />
        </div>
      </modal-or-panel-quickshop>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Breadcrumb, ColorOption } from '#types/product'
import type { ProductClickAction } from '#types/gtm'
import type { QuickshopMode } from '#types/catalog'
import type { Product, ProductCardExtended } from '#root/api/clients/product/data-contracts'
import type { CSSClass } from '#types/common'

const {
  breadcrumbs,
  imageFetchPriority: fetchpriority,
  product,
  quickshopMode,
  trackingCategory,
  showSwatches,
  hideRating
} = withDefaults(
  defineProps<{
    /**
     * Page breadcrumbs from the product list
     */
    breadcrumbs?: Breadcrumb[]
    /**
     * Allows custom title styling from the parent
     */
    classTitle?: CSSClass
    /**
     * Option to hide attributes
     */
    hideAttributes?: boolean
    /**
     * Controls lazy load property of the default product image
     */
    lazy?: boolean
    /**
     * Product data
     */
    product: Product | ProductCardExtended
    /**
     * Quickshop mode string provided by LaunchDarkly
     */
    quickshopMode?: QuickshopMode
    /**
     * Tracking category for the click and details analytics events
     */
    trackingCategory?: string
    /**
     * This prop allows showing swatches on the product card
     */
    showSwatches?: boolean
    /**
     * Sets displaying the discount amount as percentage
     */
    showDiscountPercentage?: boolean
    /**
     * This prop allows showing rating stars on the product card by LaunchDarkly
     */
    hideRating?: boolean
    /**
     * Set fetchpriority for the first product image
     */
    imageFetchPriority?: 'low' | 'high' | 'auto'
  }>(),
  {
    breadcrumbs: () => ([])
  }
)

const { $gtm, $viewport } = useNuxtApp()

const ModalOrPanelQuickshop = createDialog('quickshop', {
  wrapper: () => $viewport.lg ? (quickshopMode === 'inline' ? undefined : 'modal') : 'panel',
  teleport: () => !($viewport.lg && quickshopMode === 'inline')
})

const { addButtonSize, badgePosition, brandClasses, ratingSize, showSwatchCount }
    = useAppConfig().components.product.card
const route = useRoute()
const router = useRouter()
const quickshopRef = ref<HTMLDivElement>()
const showSecondaryMedia = ref(false)
const mode = computed(() => $viewport.lg && quickshopMode === 'inline' ? 'inline' : 'modal')

/*
 Some products can be marketed by Style (NF0A3XEO) or Style Color (NF0A3XEOLE4).
 When marketed by style we need to find the default color from the color attributes.
*/
const isStyle = PRODUCT_STYLE_ID_REGEX.test(product?.id)
const productUrl = ref(product.url)
const defaultColor = (isStyle ? getDefaultColorOption(product?.attributes) || {} : {}) as ColorOption
const activeProduct = ref(defaultColor.id || product.id)

// Fetch
const { data } = useApi().products.details(activeProduct, {}, {
  immediate: false,
  key: `productCard-${activeProduct.value}`,
  default: () => ({
    ...product,
    url: defaultColor.url || productUrl.value
  }),
  // Temporary fix for missing data from API
  transform: (input) => ({
    ...input,
    eyebrow: input.eyebrow || product.eyebrow,
    badge: input.badge || product.badge
  })
}) as { data: Ref<Product> }

const swatches = data.value?.attributes.find((attr) => attr.type === 'color')?.options as ColorOption[]

onClickOutside(quickshopRef, () => mode.value === 'inline' && ModalOrPanelQuickshop.close(), {
  ignore: ['#dialogs']
})

const handleGtmEvent = (payload, action: ProductClickAction) =>
  $gtm.push('product.onProductClick', payload, {
    action,
    breadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q?.toString()
  })

const navigateToPDP = (e?: PointerEvent) => {
  const openInNewTab = e && (e.metaKey || e.ctrlKey)

  handleGtmEvent(data.value, 'Navigate To PDP')
  const serializedBreadcrumbs = serializeAnalyticsBreadcrumbs(breadcrumbs)
  const searchParams = new URLSearchParams(data.value.url.split('?')?.[1] || '')
  const query = Object.fromEntries(searchParams.entries())

  const state = {
    breadcrumbs: serializedBreadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q
  }

  if (openInNewTab) {
    localStorage.setItem(`from_plp_${data.value.id}`, JSON.stringify(state))
  }
  else {
    e?.preventDefault()
    router.push({
      path: data.value.url,
      query,
      state
    })
  }
}

const openQuickshop = () => {
  ModalOrPanelQuickshop.open({ product: data.value })
  handleGtmEvent(data.value, 'Open Quick Shop')
}
</script>
