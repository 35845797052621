import type { Product, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { AttributeSelection, ColorOption, OptionGroups, ProductDetail, ProductExtended, UseFetchOptionsExt } from '#types/product'

export async function useProduct(
  id: MaybeRefOrGetter<string>,
  options: UseFetchOptionsExt
) {
  const { $t } = useNuxtApp()
  const { products } = useApi()
  const { benefitRatingsMaxScale, disableGroupBy } = useAppConfig().pages?.pdp || {}
  const route = useRoute()
  const productId = toValue(id)
  const detailsId = computed(() => options?.enableColorAsAttribute ? `${toValue(id)}${route.query?.color || ''}` : toValue(id))
  const isStyle = computed(() => PRODUCT_STYLE_ID_REGEX.test(detailsId.value))

  const { pending: inventoryPending, data: inventory, error: inventoryError } = products.inventory(id, {}, {
    lazy: true,
    server: false,
    key: `productInventory-${productId}`
  })

  const { pending: productPending, data: product, error: productError }
    = await products.details<ProductExtended>(detailsId, {}, {
      lazy: true,
      key: `productDetails-${detailsId.value}`,
      ...options,
      transform: async ({ customsExternalurl, details = [], modelMeasurements, ...product }) => {
        /**
         * @description: Sanitize text
         * @credit: https://uibakery.io/regex-library/html
         * @tested: https://regex101.com/r/Dfz9qs/1
         */

        // eslint-disable-next-line regexp/no-super-linear-backtracking
        const htmlRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g

        const sanitizedDetails = (details || []).map((detail) => ({
          ...detail,
          data: detail.data.map((content) => {
            for (const key in content) {
              if (typeof content[key] === 'string' && content[key].match(htmlRegex))
                content[key] = content[key].replace(htmlRegex, '')
            }

            return content
          })
        }))

        const sanitizedModelMeasurements = modelMeasurements?.map((modelMeasurement) =>
          modelMeasurement.match(htmlRegex) ? modelMeasurement.replace(htmlRegex, '') : modelMeasurement
        )

        const isBenefitRating = ({ id }) => id === 'benefitRatings'
        const groupedDetails = sanitizedDetails.filter((item) => !isBenefitRating(item))
        const benefitRatingIndex = sanitizedDetails.findIndex(isBenefitRating)

        if (benefitRatingIndex !== -1) {
          const groupedBenefitRatings = {
            expanded: sanitizedDetails[benefitRatingIndex].expanded,
            id: 'benefitRatings',
            layout: 'scale',
            label: $t.benefitRating,
            data: sanitizedDetails
              .filter(isBenefitRating)
              .flatMap(({ label, data }) => data.map(
                ({ maxValue, value, minLabel }) => ({
                  maxValue: benefitRatingsMaxScale!.toString(),
                  value: maxValue,
                  minLabel: value,
                  maxLabel: minLabel,
                  label
                })
              ))
          } satisfies ProductDetail

          groupedDetails.splice(benefitRatingIndex, 0, groupedBenefitRatings)
        }

        let defaultColor = {} as ColorOption
        // Checks if the PID is a style or style-color ID (NF0ABCDE -or- NF0ABCDE123)
        if (isStyle.value)
          defaultColor = getDefaultColorOption(product.attributes)

        const data = {
          ...product,
          customsExternalurl: customsExternalurl?.replace(/\/customizer\.(.*?)\.html/g, '/customizer/$1'),
          details: groupedDetails,
          modelMeasurements: sanitizedModelMeasurements,
          badge: defaultColor.badge || product.badge,
          defaultColor: defaultColor.value,
          id: defaultColor.id || product.id,
          eyebrow: defaultColor.eyebrow || product.eyebrow,
          price: defaultColor.price || product.price,
        }

        options?.transform?.(data)

        return data as ProductExtended
      }
    })

  return {
    inventoryPending,
    inventoryError,
    inventory,
    productPending,
    productError,
    product: computed(() => {
      if (!product.value) return null

      const isDigital = product.value.productType === 'Digital'

      let variants = Array.isArray(product.value.variants) ? product.value?.variants : []

      if (options?.enableColorAsAttribute && isStyle.value)
        variants = variants.filter((variant) => variant.attributes.color === product.value?.defaultColor)

      variants = variants.map((variant) => {
        const quantity = isDigital ? 1 : inventory.value?.variants[variant.id]?.quantity || 0

        return {
          ...variant,
          productInventoryState: quantity > 0 ? 'InStock' : 'OutOfStock' as 'InStock' | 'OutOfStock'
        }
      })

      // Check if there is any attribute option that needs to be marked as unavailable according to its inventory
      const attributes = product.value.attributes?.map((attribute) => {
        // TEMP: mitigation against API not returning type field in attribute
        if ('code' in attribute) {
          // @ts-expect-error incorrect contract type
          attribute.type = attribute.code
          delete attribute.code
        }

        return ({
          ...attribute,
          options: attribute.options.filter((option) => !(isDigital && option.label === 'X')).map((option) => {
            // VariantIds are a list of all variant IDs
            const variantIds = variants
              .filter((variant) => variant.attributes[attribute.type] === option.value)
              .map((variant) => variant.id)
            return attribute.type === 'color'
              ? {
                  ...option,
                  available: option.id === product.value?.id
                    ? variants.some(({ productInventoryState }) => productInventoryState === 'InStock')
                    : option.available
                }
              : {
                  ...option,
                  label: product.value?.productType === 'Digital' ? useFormattedPrice(option.label, product.value?.currency, { trailingZeroDisplay: 'stripIfInteger' }) : option.label,
                  available: variants.filter(({ id }) => variantIds?.includes(id))
                    .some(({ productInventoryState }) => productInventoryState === 'InStock')
                }
          })
        }) || []
      })

        .map((attribute) => {
          const isSamePrice = (price1, price2) => Object.keys(price1).every((key) => price1[key] === price2[key])

          const groupOptions = (attribute) => {
            const groups: OptionGroups[] = []
            if (!disableGroupBy && attribute.groupBy === 'price') {
              const uniquePrices = [attribute.options[0].price].filter(Boolean)

              // find unique prices
              attribute.options.forEach((option) => {
                if (option.price && uniquePrices.every((price) => !isSamePrice(price, option.price)))
                  uniquePrices.push(option.price)
              })

              // sort prices from highest to lowest
              uniquePrices.sort((a, b) => a.lowCurrent < b.lowCurrent ? 1 : -1)

              // create an option group for each unique price
              uniquePrices.forEach((price) => {
                groups.push({
                  groupValue: price,
                  options: attribute.options.filter((option) => isSamePrice(price, option.price))
                })
              })
            }
            else {
              groups.push({ options: attribute.options })
            }
            return groups
          }

          return ({
            ...attribute,
            groupedOptions: groupOptions(attribute)
          })
        })

      return {
        ...product.value,
        variants,
        attributes,
        allVariantsOutOfStock: variants.every(({ productInventoryState }) => productInventoryState === 'OutOfStock'),
      }
    })
  }
}

export function useAttributeSelectionLabels(
  product: MaybeRefOrGetter<Product | null>,
  selection: MaybeRefOrGetter<AttributeSelection>
) {
  return computed(() => (toValue(product)?.attributes || []).reduce((attributes, { type, options }) => {
    attributes[type] = options?.find((option) => option.value === toValue(selection)[type])?.label
    return attributes
  }, {} as ProductVariants[number]['attributes']))
}
