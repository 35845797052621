<template>
  <!-- We should extract "validate" as a standalone function in order to not use the BaseForm wrapper -->
  <component :is="asForm ? BaseForm : 'div'" ref="attributeSelectionFormRef" :form="attributeSelection">
    <div :class="brandClasses.variantSelectionWrapper">
      <section
        v-for="({ groupBy, groupedOptions, label, type }, i) in variantAttributes"
        :key="type"
        :data-test-id="`${type}-groups`"
        :data-scroll-el="`${type}Pickers`"
      >
        <div :class="{ 'flex items-center space-x-2': showBadge }">
          <div v-if="showBadge" class="h-4 w-4 flex justify-center rounded-full bg-grey-10">
            <span class="text-xs c-white fw-bold">{{ i + 1 }}</span>
          </div>
          <div :id="label" class="elevate-body-3">
            <h2 class="inline">
              {{ label }}:
            </h2>
            <p class="inline">
              {{ attributeSelectionLabels[type] }}
            </p>
          </div>
        </div>
        <vf-form-error v-if="type !== 'color' && error && !attributeSelection[type]" class="mt-1">
          {{ product.productType === 'Digital' ? $t.selectCardAmountError : $t.selectSizeError }}
        </vf-form-error>
        <div
          v-for="({ groupValue, options }, j) in groupedOptions"
          :key="j"
          class="mt-4"
          :class="{ 'mb-4': j !== groupedOptions.length - 1 }"
          :data-test-id="`${type}-groups-item`"
        >
          <product-pricing
            v-if="groupValue && groupedOptions.length > 1 && groupBy === 'price'"
            :price="groupValue"
            :currency="product.currency"
            class="mb-2"
          />
          <fieldset
            v-if="type === 'color'"
            :aria-labelledby="label"
            class="flex wrap"
            :class="{ 'gap-2': isRectangle }"
          >
            <vf-carousel
              class-container="gap-2 p-1 -m-1 <lg:w-screen <lg:scroll-px-4 <lg:px-4 <lg:-mx-4 lg:wrap"
              class-controls="!hidden"
            >
              <base-popover
                v-for="{ available, id, label: optionLabel, swatch, url, value } in options"
                :key="optionLabel"
                v-slot="{ close, open }"
                :offset="6"
              >
                <vf-color-picker
                  :color="swatch?.color"
                  :thumbnail="swatch?.image.replace('-SW', product.productType === 'Clothing' ? '-ALT10' : '-HERO')"
                  :active="!pending && getIsActive(value, id)"
                  :loading="getIsPending(value, id, pending)"
                  :size="colorPickerSize"
                  :variant="colorPickerShape"
                  :unavailable="!available && !pending"
                  :name="optionLabel"
                  @click="selectNewColor(enableColorAsAttribute ? value : id, url, optionLabel)"
                  @focus="open"
                  @blur="close"
                  @mouseenter="open"
                  @mouseleave="close"
                  @keydown.esc="close"
                />
                <base-popover-content
                  enter-from-class="op-0 scale-95"
                  enter-active-class="transform ease-out"
                  enter-to-class="translate-y-0 scale-100"
                  leave-from-class="translate-y-0 scale-100"
                  leave-active-class="transform ease-in"
                  leave-to-class="op-0 scale-95"
                  role="tooltip"
                  class="z-overlay ws-nowrap rounded bg-grey-90 p-2 text-sm shadow-xs transition dark:bg-grey-10"
                >
                  {{ optionLabel }}
                </base-popover-content>
              </base-popover>
            </vf-carousel>
          </fieldset>
          <fieldset v-else-if="type === 'width'" :aria-labelledby="label">
            <base-form-field :name="type" :rule="validateRequired()">
              <div class="relative h-12 flex rounded-full bg-grey-90 lg:h-14 dark:bg-grey-10">
                <base-radio
                  v-for="{ label: optionLabel, value } in options"
                  :key="optionLabel"
                  :model-value="attributeSelection[type]"
                  :value
                  class="full cursor-pointer"
                  :style="{ width: `${100 / options.length}%` }"
                  @update:model-value="$emit('selectSize', type, value, optionLabel)"
                >
                  <span
                    v-if="attributeSelection[type]"
                    class="absolute-0 b b-current rounded-full bg-white transition-transform dark:bg-black peer-focus-visible:outline-auto"
                    :style="{
                      width: `${100 / options.length}%`,
                      transform: `
                          translateX(${(options.findIndex(option => option.value === attributeSelection[type])) * 100}%)
                        `,
                    }"
                  />
                  <span
                    class="relative z-1 full flex center"
                    :class="[{ 'fw-bold': attributeSelection[type] === value }]"
                  >
                    {{ optionLabel }}
                  </span>
                </base-radio>
              </div>
            </base-form-field>
          </fieldset>
          <fieldset v-else :aria-labelledby="label">
            <base-form-field v-slot="{ attrs }" :name="type" :rule="validateRequired()">
              <div class="flex gap-2 wrap">
                <vf-size-picker
                  v-for="({ available, label: optionLabel, value }, n) in options"
                  :key="optionLabel"
                  :type
                  :model-value="attributeSelection[type]"
                  :value
                  :name="label"
                  :required="n === 0"
                  :unavailable="!pending && (!available || !getAttrOptionAvailable(type, value))"
                  v-bind="attrs"
                  @input="$emit('selectSize', type, value, optionLabel)"
                >
                  {{ optionLabel }}
                </vf-size-picker>
              </div>
            </base-form-field>
          </fieldset>
        </div>
        <div v-if="isComingSoon" class="mt-4 space-y-4">
          <p class="title-4 c-grey-20">
            {{ comingSoonText }}
          </p>
          <p v-if="selectedVariant?.notifyMe">
            {{ replaceAll($t.comingSoonDescription, { productName: product.name }) }}
          </p>
        </div>
      </section>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { formatDate } from '@vueuse/core'
import { BaseForm } from '#components'
import type { BaseForm as BaseFormType } from '#components'
import type { AttributeSelection, ColorSwatchShape, ProductAttributeType, ProductExtended, SelectedVariant } from '#types/product'
import type { Responsive } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'

const props = defineProps<{
  /*
  * variants, showNotifyMe, productInventory props are added only for consistency with other brands
  * They aren't used in the component
  * but allows to avoid situations with incorrect props resolution when rendering the DOM
  */
  attributeSelectionLabels: Record<string, string>
  colorSize?: SizesSubUnion<'sm' | 'md' | 'lg'> | Responsive<SizesSubUnion<'sm' | 'md' | 'lg'>>
  colorSwatch?: ColorSwatchShape
  error?: boolean
  isComingSoon?: boolean
  pending?: boolean
  product: ProductExtended
  selectedVariant?: SelectedVariant | null
  showBadge?: boolean
  asForm?: boolean
  showNotifyMe?: any
  showSizeAndFit?: boolean
  variants?: any
}>()

const emit = defineEmits<{
  selectColor: [id: string, url: string, optionLabel: string]
  selectSize: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
}>()

const { brandClasses, enableColorAsAttribute, swatches } = useAppConfig().pages.pdp || {}
const { $feature, $t } = useNuxtApp()
const attributeSelectionFormRef = defineModel<InstanceType<typeof BaseFormType> | null>('formRef', { required: false })

const colorPickerShape = props.colorSwatch ?? swatches.variant
const colorPickerSize = props.colorSize ?? swatches.size
const isRectangle = colorPickerShape === 'rectangle'
const attributeSelection = defineModel<AttributeSelection>({ required: true })

const selectedProductId = ref()

// Attributes should be listed as: Color, Width, followed by any remaining attributes
const variantAttributesOrder = ['color', 'width']

const variantAttributes = computed(() =>
  props.isComingSoon
    ? props.product.attributes.filter((attribute) => attribute.type === 'color')
    : props.product.attributes.toSorted((_a, b) => variantAttributesOrder.includes(b.type) ? 1 : -1)
)

const selectNewColor = (id, url, optionLabel) => {
  selectedProductId.value = id
  emit('selectColor', id, url, optionLabel)
}

const getIsActive = (value: string, id: string) =>
  enableColorAsAttribute ? attributeSelection.value.color === value : props.product.id === id

const getIsPending = (value: string, id: string, pending?: boolean) => {
  if (!pending) return

  return enableColorAsAttribute
    ? attributeSelection.value.color === value
    : selectedProductId.value === id
}

const comingSoonText = computed(() => {
  return props.product.targetPublishDate
    ? `${$t.comingSoon} - ${
          formatDate(new Date(props.product.targetPublishDate.replace(/-/g, '\/')), $feature.configProductComingSoonDateFormat)}`
    : $t.comingSoon
})

// This logic possibly can be moved to a useProduct
function getAttrOptionAvailable(type: ProductAttributeType, value: string) {
  // Attribute types except a current type and 'color'
  const otherAttributeTypes = props.product.attributes
    .filter((attribute) => !['color', type].includes(attribute.type))
    .map((attribute) => attribute.type)

  if (otherAttributeTypes.every((attributeType) => attributeSelection.value[attributeType])) {
    // If all other attribute types are selected, find the current option's variant
    const currentVariant = props.product.variants.find((variant) =>
      otherAttributeTypes.every((attributeType) =>
        variant.attributes[attributeType] === attributeSelection.value[attributeType])
        && variant.attributes[type] === value)

    return currentVariant?.productInventoryState === 'InStock'
  }

  // When the attribute option has other unselected attribute types, it's available
  return true
}

defineExpose({
  validate: () => attributeSelectionFormRef.value?.validate()
})
</script>
