<template>
  <product-list-header
    :list
    :products-loading="productsLoading"
    :next-page-loading="nextPageLoading"
    :side-filters="sideFilters"
    :title="headerTitle"
  />

  <div
    v-if="gridSizeSelector && (list?.products.length || hasFilters)"
    class="flex justify-end gap-2 py-4 container <lg:hidden"
  >
    <base-button
      v-for="(count, label) in { [$t.defaultView]: '4', [$t.compactView]: '6' }"
      :key="count"
      :aria-pressed="colsLg === count"
      @click="colsLg = count"
    >
      <span class="sr-only">{{ label }}</span>
      <span
        class="block cursor-pointer b-b duration peer-focus-visible:outline-auto "
        :class="colsLg === count ? 'b-grey-10 c-grey-10' : 'b-transparent c-grey-40 hover:b-grey-40'"
      >
        <vf-icon :name="`square-${count}`" size="md" />
      </span>
    </base-button>
  </div>

  <base-grid
    :cols="{ lg: sideFilters ? grid.cols.lg : null }"
    :gap-x="grid.gaps.x"
    class="pt-6 container "
  >
    <div
      v-if="sideFilters"
      class="scrollbar-sm sticky top-0 h-screen self-start overflow-x-hidden overflow-y-auto pl-3 pr-2 <lg:hidden"
      style="scrollbar-gutter: stable"
    >
      <filter-display
        v-bind="{ filters, currency, sideFilters }"
        :max-collapsed-rows="{ sm: 2, md: 1, lg: 2 }"
        class="b-t b-grey-70 py-2"
        @remove="removeFilter"
        @clear="clearFilters"
      />
      <template v-if="hasFilters">
        <vf-accordion
          v-for="(filter, code, i) in filters"
          :key="code"
          :open="i < sideFilterOpenFacets"
          :title="filter.label"
          size="sm"
          variant="aligned"
        >
          <filter-options
            v-bind="{ filter, code }"
            v-model="filters[code].selected"
            class="pb-6 pt-2"
            :loading="productsLoading"
            :currency
            @update:model-value="filterBy(code.toString())"
          />
        </vf-accordion>
      </template>
      <product-list-skeleton v-else-if="productsLoading" />
    </div>
    <div :class="{ 'lg:col-span-3': sideFilters, 'pb-16 ': products.length }">
      <p
        v-if="!productsLoading && !products.length"
        class="mb-30 mt-12 text-center lg:mt-10 title-2 fw-bold "
      >
        {{ $t.noResults }}
      </p>
      <!-- Product grid -->
      <base-grid v-else ref="gridRef" :cols="cols" :gap-x="grid.gaps.x" :gap-y="grid.gaps.y">
        <!-- hydration mode is set to visible because we need to calculate the number of hidden product variants -->
        <template v-if="!productsLoading">
          <template v-for="(product, i) in products" :key="product.id">
            <base-lazy-hydrate v-if="i < renderedProducts" when="interaction" :interaction="['mouseenter', 'focus']">
              <product-card
                v-bind="{ breadcrumbs, hideRating, product, quickshopMode, trackingCategory }"
                :lazy="i >= +colsLg"
                :show-swatches="$feature.showSwatchesInProductList"
                :show-discount-percentage="$feature.showDiscountPercentageOnCatalog"
                :image-fetch-priority="i < +colsLg ? 'high' : 'auto'"
              />
            </base-lazy-hydrate>

            <!-- Makes sure all product links are available after SSR and can be crawled -->
            <base-lazy-hydrate v-else when="visible">
              <base-link ref="lazyLoadRefs" :aria-label="product.name" :to="product.url">
                <product-card-skeleton :show-swatches="$feature.showSwatchesInProductList" />
              </base-link>
            </base-lazy-hydrate>
          </template>

          <slot v-bind="{ cols }" />
        </template>
        <!-- Skeleton -->
        <template v-if="productsLoading || nextPageLoading">
          <product-card-skeleton
            v-for="i in (nextPageLoading ? nextPageSize : productsPerPage)"
            :key="`product-${i}`"
            :show-swatches="$feature.showSwatchesInProductList"
          />
        </template>
      </base-grid>

      <!-- Pagination Skeleton -->
      <product-list-pagination-skeleton v-if="productsLoading" />
      <!-- Pagination -->
      <product-list-pagination
        v-else-if="products.length"
        class="mt-12 "
        :current="products.length"
        :total
        :page-size="nextPageSize"
        @load-more="loadMore"
      />
    </div>
  </base-grid>
</template>

<script lang="ts" setup>
import type { QuickshopMode } from '#types/catalog'
import type { Catalog, Search } from '#root/api/clients/product/data-contracts'
import type { Responsive } from '#types/common'
import type { BaseGrid as BaseGridType } from '#components'

const props = defineProps<{
  list: Catalog | Search | null
  productsLoading: boolean
  nextPageLoading: boolean
  trackingCategory?: string
  /**
   * values provided by LaunchDarkly
   */
  sideFilters: boolean
  hideRating?: boolean
  quickshopMode: QuickshopMode
  gridSizeSelector: boolean
  headerTitle?: string
}>()

const emit = defineEmits<{
  'load-more': []
}>()

defineSlots<{
  default: (props: { cols: Responsive<number> }) => void
}>()

const { eagerLoadedCards, grid, productsPerPage } = useAppConfig().components.product.list
const { sideFilterOpenFacets } = useAppConfig().pages.plp
const { $t, $feature } = useNuxtApp()

const { filters, filterBy, removeFilter, clearFilters } = useFilters(
  () => props.list?.filters || [],
  () => scrollToElement('productList', { offset: 0 })
)

const total = computed(() => props.list?.total || 0)
const currency = computed(() => props.list?.currency || 'USD')
const breadcrumbs = computed(() => props.list?.breadcrumbs || [])
const colsLg = ref(`${props.sideFilters ? grid.cols.lg - 1 : grid.cols.lg}`)
const products = computed(() => props.list?.products || [])
const cols = computed(() => ({ ...grid.cols, lg: +colsLg.value }))
const nextPageSize = computed(() => Math.min(productsPerPage, total.value - products.value.length))
const hasFilters = computed(() => !!Object.keys(filters.value)?.length)

const gridRef = ref<typeof BaseGridType>()
const lazyLoadRefs = ref<HTMLAnchorElement[]>([])
const productsToRender = ref(eagerLoadedCards)
const renderedProducts = computed(() => Math.min(productsToRender.value, products.value.length))

const loadMore = () => {
  gridRef.value?.$el.lastElementChild.querySelector('#viewMoreCTA')?.focus()
  emit('load-more')
}

const lazyLoadChunk = (entries: IntersectionObserverEntry[]) => entries.some(({ isIntersecting, boundingClientRect }) =>
  isIntersecting // Prevents the re-rendering of the grid from triggering the logic prematurely
  || boundingClientRect.bottom < 0 // Forces any observed refs above the viewport after SSR to be triggered
)

useIntersectionObserver(lazyLoadRefs, (entries) => {
  if (lazyLoadChunk(entries)) productsToRender.value += eagerLoadedCards
})
</script>
